<template>
    <div>
        <div class="box_title flex sm-" style="justify-content: space-between;">
            <Title title="素材提交" :backShow="false" />
            <div class="flex" v-if="$route.query.id">
                <button @click.stop="$router.back()" class="but">返回</button>
                <button @click.stop="updateFn" class="but">修改</button>
            </div>
        </div>
        <div class="ct_box">
            <el-form :model="placard.fill_form" :rules="rules" ref="placard.fill_form">
                <el-form-item prop="title">
                    <!-- <el-input v-model="$store.state.FillForm.title" placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"></el-input> -->
                    <div class="flex input_box">
                        <div class="title_box">
                            <p class="title"><span>*</span>素材名称</p>
                        </div>
                        <!-- <input class="input_text" v-model="$store.state.FillForm.title"
							placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知" /> -->
                        <el-input class="title_input" v-model="placard.fill_form.title"
                            placeholder="标题建议25字符以内，字符过多的，广告主修改标题不通知"></el-input>
                        <span class="num">{{ placard.fill_form.title.length }}/50</span>
                    </div>
                </el-form-item>
            </el-form>
            <div>
                <zip_upload @upload_success="upload_success" :up_load_url="placard.fill_form.file"
                    :up_load_name="placard.fill_form.fill_name" />
                <div class="flex input_box">
                    <div class="title_box2">
                        <p class="title"><span></span>稿件内容</p>
                    </div>
                    <div style="width: 815px;">
                        <vue-ueditor-wrap id="tinymce" v-model="placard.fill_form.content"
                            :config="myConfig"></vue-ueditor-wrap>
                    </div>
                </div>
                <div class="input_box flex">
                    <div class="title_box">
                        <p class="title"><span></span></p>
                    </div>
                </div>
            </div>
        </div>
        <set_placard />
    </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import VueUeditorWrap from 'vue-ueditor-wrap'
import set_placard from '@/components/set_placard'
import { mapMutations, mapState } from 'vuex'
import zip_upload from '@/components/zip_upload'
export default {
    computed: {
        ...mapState(['placard']),
        ...mapMutations(['RESET_FILL_FORM', 'SET_PLACARD_FILE'])
    },
    components: {
        Title,
        VueUeditorWrap,
        set_placard,
        zip_upload
    },
    mounted() {
        if (this.$route.query.id) {
            // 如果有id传入,则为修改
            this.curlGet('/api/users/guanggao_order/detail', {
                id: this.$route.query.id
            }).then(res => {
                this.placard.car_list = []
                if (res.data.code) {
                    this.placard.fill_form.category_id = res.data.data[0].category_id
                    for (const key in res.data.data[0]) {
                        this.placard.fill_form[key] = res.data.data[0][key]
                    }
                    this.$store.commit('SET_PLACARD_FILE', {
                        index: 'fill_name',
                        file: res.data.data[0].file,
                    })
                    console.log(this.placard.fill_form);
                }
            })
        }
    },
    data() {
        return {
            user_info: {},
            myConfig: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 400,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: `/api/common/getupload?token=${this.$user_info.token}`,
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/',
                initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。<br/>4.图片文件最大上传不超过3MB；<br/>5.视频文件最大上传不超过20MB；',
                autoClearinitialContent: true,
                toolbars: [
                    [
                        "fullscreen",
                        "source",	// html 源码
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "link",
                        "unlink",
                        "|",
                        "simpleupload",
                        "insertimage",
                        "insertvideo",
                        "|",
                        "bold",
                        "forecolor",
                        "|",
                        "justifyleft",
                        "justifycenter",
                        "justifyright",
                        "justifyjustify",
                        "|",
                        "fontfamily",
                        "fontsize",
                        "|",
                        "removeformat",
                        "formatmatch",
                        "autotypeset",
                    ]
                ],
            },

            rules: {
                title: [
                    { required: true, message: '请输入素材名称', trigger: 'blur' },
                    { min: 1, max: 50, message: '标题长度在 1 到 50 个字符', trigger: 'blur' }
                ],
            },

        }
    },
    methods: {
        upload_success(data) {
            // this.placard.fill_form.file = data.file
            this.$store.commit('SET_PLACARD_FILE', {
                file: data.file,
                index: 'file'
            })
            this.$store.commit('SET_PLACARD_FILE', {
                file: data.name,
                index: 'fill_name'
            })
        },
        back() {
            this.$router.push('/placard_user/placard_fill')
        },
        // 修改
        updateFn() {
            if (this.placard.title == '') {
                return this.$message({
                    type: 'warning',
                    message: '请输入稿件标题'
                });
            }
            if (this.placard.content == '') {
                return this.$message({
                    type: 'warning',
                    message: '请选择文件提取文章或输入文章'
                });
            }
            this.$confirm('是否修改文稿?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '再想想',
            }).then(() => {
                let data = {}
                data.id = this.$route.id
                data.title = this.placard.fill_form.title
                data.content = this.placard.fill_form.content
                if (this.placard.fill_form.file) {
                    data.file = this.placard.fill_form.file
                }
                this.curlPost('/api/users/guanggao_order/modify',).then(res => {
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: res.data.msg
                        });
                    }
                    this.$store.commit('RESET_FILL_FORM')
                    this.$router.push('/placard_user/place_order')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消修改'
                });
            });
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/Fill';
</style>